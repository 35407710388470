.Login{
    margin: 80px auto;
    margin-bottom: 340px;
    width: 60%;
    text-align: center;
    box-shadow: 0 2px 3px #ccc;
    border: 1px solid #eee;
    padding: 10px;
    box-sizing: border-box;
}
  
.Button{
  background-color: #4CAF50;
  border: 2px solid #4CAF50;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  margin: 10px;
}

  .Button:disabled{
  color: #ccc;
	cursor: not-allowed;
  }

  .Invalid{
  	border: 1px solid red;
  	background-color: salmon;
  }

  @media (min-width: 600px){
    .Login {
      width: 500px;
    }
  }
  
/*#enviar{
	background-color: #4CAF50;
	border: 2px solid #4CAF50;
	border-radius: 8px;
	color: white;
	cursor: pointer;
}
#cancelar{
	background-color: #DC143C;
	border: 2px solid #DC143C;
	border-radius: 8px;
	color: white;
	cursor: pointer;
}*/