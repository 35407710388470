.Planapo{
    margin-top: 40px;
}
.planosCinza{
    margin-top: 50px;
    background-color: #DEDEDE ;
    margin: auto;
    border-radius: 5px;;
}
.planos{
    color: #FFFFFF;
    font: Bold 26px/43px Roboto Slab;
    letter-spacing: 0; 
    opacity: 1;
    background-color: #77B81E;
    background-image: url('../../../assets/images/FundoPlanos.png');
    background-repeat: no-repeat;
    text-align: center;
    margin-top: 50px;
    height: 80px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.Planapo a{
    text-decoration: underline;
    color: #707070;
}
@media (max-width: 1300px){
    .planos{
        height: 120px;
       
        padding-bottom: 30px;
        padding-top: 30px;
    }
}
.card{
    margin-bottom: 50px;
}