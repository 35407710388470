.Feiras{
    margin-top: 40px;
}

.Feiras a{
    text-decoration: underline;
    color: #707070;
}

@media (max-width: 550px){
    .planos{
        height: 150px;
        padding: 20px;
    }
} 

.Feiras b{
    font: Bold 18px/24px Roboto Slab;
    color: #1A2D00;
}
.Feiras b:hover{
    color: #77B81E;
}