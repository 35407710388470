.Home{
    margin-top: 80px;
}
@media (max-width: 800px){
    .Home{
        margin-top: 60px;
    }

    .Home .NeaesFoto{
        display: none;
    }
}

.p{
    text-align: justify;
}

.img2{
    height: 150px;
    margin-left: -50px;
    position: absolute;
    margin-top: 250px;
}
.bloco1{
    text-align: left;
    font: 18px Poppins;
    color: #707070;
    text-align: justify;
}
.botao1{
    background-color: #77B81E;
    border: #77B81E;
    float: center;
    font: 20px Roboto Slab;
    width: 170px;
    cursor: pointer;
    
}
.titulo2{
    color: #77B81E;
    text-align: center;
    font: Bold 32px/43px Roboto Slab;
    margin-top: 4%;
}
.album{
    background-color: #EDEDED;
    margin-top: 60px;
    /* margin-bottom: 60px; */
}
.bloco2{
    background-color: #EDEDED;
    text-align: center;
}
.bloco3{
    font: Regular 18px/28px Poppins;
    text-align: center;
    height: 500px;
}

.bloco3 h5{
    font: Bold 20px/26px Roboto Slab;
    color: #77B81E;
}

.bloco3:hover{
    background-color:  #77B81E;
    color: white;
}

.bloco3:hover .tituloBox{
    color: black;
}

.titulo3{
    color :  #77B81E;
    font: Bold 30px/40px Roboto Slab;
    opacity: 1;
    text-align: center;
}

/*Bloco 4 */

.Home .Bloco4 {
    display: flex;
}

.Home .Bloco4 .cartao{
    display: block;
    margin: 0 5% 2% 5%;
    border: 1px lightgray solid;
}

.Home .Bloco4 .cartao-corpo{
    padding: 20px;
    width: 100%;
}

.Home .Bloco5 {
    display: flex;
}

.Home .Bloco5 img{
    width: 100%;
}

.Home .Bloco5 .Form{
    width: 100%;
}

@media (min-width: 1000px){
    /* .cartao{
        position: relative;
    } */

    .Home .Bloco4 .cartao{
        position: relative;
        display: flex;
        margin: 0% 2% 2% 2%;
        border: 1px lightgray solid;
    }

    .Home .Bloco4 .cartao-image{
        width: 50%;
    }

    .Home .Bloco4 .cartao-corpo{
        width: 50%;
        margin-left: 5px;
    }

    .Bloco5{
        display: flex;
    }

    .Home .Bloco5 img{
        width: 50%;
    }

    .Home .Bloco5 .Form{
        width: 50%;
    }
}

@media (min-width: 1200px){
    .Home .Bloco4 .cartao{
        display: flex;
        margin: 0% 2% 2% 2%;
        border: 1px lightgray solid;
    }

    .Home .Bloco4 .cartao-image{
        width: 40%;
    }

    .Home .Bloco4 .cartao-corpo{
        width: 60%;
        margin-left: 5px;
    }
}
