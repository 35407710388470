.Form{
    background: #77B81E;
    /* margin-top: -10px;
    margin-bottom: 15px; */
}

.Form label{
    margin-bottom: 0;
}

.Form input,
.Form select
{
    margin-bottom: 5%;
}

.fundo2{
    margin:5%;
}
.titulo_form1{
    text-align: left;
    font-size: 15px;
    letter-spacing: 0;
    color: black;
    font-weight: bold;
    opacity: 1;
}
.titulo_form2{
    color: #FFFFFF;
    text-align: left;
    font: Bold 26px/43px Roboto Slab;
    letter-spacing: 0;
    opacity: 1;
}
.botao2{
    text-align: center;
    font: Bold 16px/21px Roboto Slab;
    letter-spacing: 0;
    color: #707070;
    opacity: 1;
    background: #FFFFFF;
    padding: 10px;
    width: 150px;
    /* margin-top: 20px; */
}
