.home{
    margin-top: 40px;
}
.texto1{
    text-align: left;
    font-size: 18px;
    font-family: Poppins;
    letter-spacing: 0;
    color: #707070;
    opacity: 1;
    margin-top: 30px;
    text-align: justify;
}
.fundo_cinza{
    text-align: left;
    letter-spacing: 0;
    color: #707070; 
    opacity: 1;
    background: #EDEDED;
}
.card{
    margin-top: 15px;
}
.Pnae a{
    text-decoration: underline;
    color: #707070;
}

.link b{
    text-decoration: underline;
    font: Bold 18px/24px Roboto Slab;
    color: #1A2D00;
}

.link b:hover{
    color: #77B81E;
}
