.Mapeamento{
    margin-top: 40px;
    margin-bottom: 40px;
}

.Mapeamento a{
    text-decoration: underline;
    color: #707070;
}

.Mapeamento img{
    margin-bottom: 8px;
}
