.SideDrawer{
    top: 0;
    left: 0;
    position: fixed;
    width: 400px;
    max-width: 75%;
    height: 100%;
    z-index: 200;
    background-color: white;
    padding: 42px 1px;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
}

/* .SideDrawer .Logo{
    margin-left: 20px;
} */

@media (min-width: 800px){
    .SideDrawer {
        display: none;
    }
}

.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(-100%);
}