.DiagnosticoRural{
    margin-top: 40px;
}
.DiagnosticoRural a{
    text-decoration: underline;
    color:#707070;   
}

.DiagnosticoRural b{
    font: Bold 18px/24px Roboto Slab;
    color: #1A2D00;
}
.DiagnosticoRural b:hover{
    color: #77B81E;
}