.TecnologiasSociais{
    margin-top: 40px;
}

.TecnologiasSociais a{
    text-decoration: underline;
    color: #707070;
}

.fundoImagem{
    background-image: url('../../assets/images/Fundo_Tela.png');
    background-repeat: no-repeat;
}