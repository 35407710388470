.Toolbar {
    position: fixed;
    top: 0;
    left: 0;
    /* top: 5%; */
    /* margin-left: 10%;
    margin-right: 10%; */
    height: 90px;
    width: 100%;
    /* display: flex; */
    /* justify-content: space-between; */
    align-items: center;
    /* padding: 0 20px; */
    /* box-sizing: border-box; */
    /* background: #FFFFFF 0% 0% no-repeat padding-box; */
    background-color: #77B81E;
    opacity: 1;
    z-index: 90;
}

.Toolbar .Titulo{
    opacity: 1;
    text-align: center;
    font: Bold 38px/50px Roboto Slab;
    letter-spacing: 0;
    height: 128px;
    width: 100%;
    margin-top: 1%;
}

.Toolbar .TituloBranco{
    color: white;
}

/* .Toolbar nav{
    /* height: 100%; 
} */

@media (max-width: 800px){
    .DesktopOnly{
        display: none;
    }

    .Toolbar{
        height: 61px;
    }
}