.QuemSomos{
    margin-top: 40px;
}

.QuemSomos a{
    text-decoration: underline;
    color: #707070;
}

.QuemSomos strong{
    font: Bold 19px/24px Roboto Slab;
    color: #1A2D00;
}

#titleLinhas {
    font: Bold 22px/28px Roboto Slab;
    color: #77B81E;
}