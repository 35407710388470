.Visitas{
    margin-top: 40px;
}

.Visitas a{
    text-decoration: underline;
    color: #707070;
}

.Visitas strong{
    font: Bold 20px/27px Roboto Slab;
    color: #1A2D00;
}

.Visitas b{
    font: Bold 18px/24px Roboto Slab;
    color: #1A2D00;
}
.Visitas b:hover{
    color: #77B81E;
}