.image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .container:hover .image {
    opacity: 0.5;
  }
  
  .container:hover .middle {
    opacity: 1;
  }
  
  .text {
    background-color: #1A2D00B3;
    color: white;
    font-size: 16px;
    padding: 16px 32px;
  }