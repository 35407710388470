.TopoDaPagina{
    height:180px;
    background-color: #2F4F2F;
    color: white;
    margin-top: 90px;
    align-items: center;
    vertical-align: center;
    width: 100%;
    display: flex;
    background-image: url('../../assets/images/TopoDaPagina.png');
}

.TituloTopo{
    text-align: left; 
    font: Bold 32px/43px Roboto Slab;
    margin-left: 5%;
    opacity: 1;
    width: 50%;
}

.CaminhoTopo{
    font: Regular 18px/28px Poppins;
    right: 400px;
    text-align: right;
    width: 50%;
    margin-right: 5%;
}

@media (max-width: 800px){
    .TopoDaPagina{
        margin-top: 60px;
    }
}