.Footer {
    background-color: #1A2D00;
    z-index: 90;
    color: white;
}

.Footer .Titulo{
    color: #77B81E;
    font: Bold 18px/28px Roboto Slab;
    border-bottom: 2px solid #77B81E;
    width: 200px;
    text-align: left;
}

.Footer .Bloco{
    width: 100%;
    /* font-size: 1.0em; */
    padding: 1% 3%;
    font-family: 'Poppins', sans-serif; 
    /* font-size: 18px;  */
}

.Footer p{
    color: white;
    margin-top: 10px;
}

.Footer a{
    color: white;
}

.Footer li{
    text-align: left;
    list-style: none;
    /* margin-top: 10px; */
}

.Footer .Icone{
    width: 25px;
    background-color: #77B81E;
    border-radius: 50%;
    text-align: center;
    margin-right: 10px;
    margin-bottom: 10px;
}

.Footer .IconeFacebook{
    width: 30px;
}

.Footer .Fone,
.Footer .Email,
.Footer .Facebook{
    display: flex;
}

.Footer .Facebook{
    font: Bold 20px/26px Roboto Slab;
}

.Footer .Facebook p{
    margin-right: 10px;
}

/* .Footer img{
    background-color: chartreuse;
    border-radius: 50%;

} */

.Footer .Projeto{
    color: white;
}

.IfspLogo{
    width: 50px;
    padding: 2px 5px;
}

.NeaesLogo{
    width: 50px;
    padding: 2px 5px;
}

.License{
    padding: 2px 5px;
}

.FacebookLogo{
    width: 40px;
    padding: 2px 5px;
}

.EmailLogo{
    width: 40px;
    padding: 2px 5px;
}

@media (min-width: 600px){
    .Footer {
        display: flex;
        justify-content: center;
    }

    .Footer .Bloco{
        width: 30%;
    }
}

.LinkCreative{
    color: #dcdcdc;

}
.LinkCreative:hover {
    color: #1A2D00;
    text-decoration: none;
}

.lowerFooter{
  background-color: #77B81E;
  padding: 15px ;
  color: #fff;
  font-size: 15px;

}
/* @media (min-width: 400px) {
    font-size: initial;
} */