.CursosFic{
    margin-top: 40px;
}

.CursosFic a{
    text-decoration: underline;
    color: #707070;
}
.CursosFic .Carrossel{
    display: none;
}

@media(min-width: 800px){
    .CursosFic .Carrossel{
        display: flex;
    }

}