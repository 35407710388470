.Pnae{
    margin-top: 40px;
}

.Pnae a{
    text-decoration: underline;
    color: #707070;
}

.textoAcessar{
    color: #707070;
    opacity: 1;
    letter-spacing: 0;
    font-size: 18px;
    font-family: Poppins;
}