.DrawerToggle {
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;
    align-items: center;
}

.DrawerToggle .Tit{
    width: 80%;
    opacity: 1;
    font: Bold 20px Roboto Slab;
    margin-top: 2%;
    margin-left: 2%;
}

.DrawerToggle .TitBranco{
    color: white;
}

.DrawerToggle .Menu {
    width: 20%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    cursor: pointer;
    color: white; 
}

.DrawerToggle .CloseMenu{
    width: 20%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    cursor: pointer;
    color: white;
}

.DrawerToggle .Menu div {
    width: 30px;
    height: 5px;
    background-color: white;
    /* margin-right: 1%; */
    border-radius: 15%;
}

@media (min-width: 800px) {
    .DrawerToggle {
        display: none;
    }
}