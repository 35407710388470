.MetodologiasParticipativas{
    margin-top: 40px;
}

.MetodologiasParticipativas a{
    text-decoration: none;
    color:#77B81E;   
    vertical-align: middle; 
}

.MetodologiasParticipativas strong{
    font: Bold 20px/27px Roboto Slab;
    color: #1A2D00;
}