.NavigationItem {
    /* margin-top: 10px; */
    margin-left: -30px;
    box-sizing: border-box;
    display: block;
    width: 100%;
    font-family: 'Roboto Slab', serif;
    font-weight: bold;
    font-size: 15px;
    color: #707070;
}

.NavigationItem a{
    color: #707070;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
    max-width: 180px;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
    color: #1A2D00;
}

@media (min-width: 500px){
    .NavigationItem {
        /* margin: 0; */
        display: flex;
        /* height: 100%; */
        width: auto;
        align-items: center;
    }
    
    .NavigationItem a{
        /* height: 100%; */
        padding: 16px 30px;
    }
    
    .NavigationItem a:hover,
    .NavigationItem a:active,
    .NavigationItem a.active {
        /* background-color: #006600;
        border-bottom: 4px solid rgb(10, 10, 10); */
        /* border-bottom: 5px solid #77B81E; */
        /* color: white;  */
    }
}