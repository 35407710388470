.CooperativaCooperflora{
    margin-top: 40px;
}

.CooperativaCooperflora a{
    text-decoration: underline;
    color: #707070;
}

.CooperativaCooperflora strong{
    font: Bold 20px/27px Roboto Slab;
    color: #1A2D00;
}
.player-wrapper {
    position: relative;
    padding-top: 50.00% /* Player ratio: 100 / (1280 / 720) */
  }

.react-player {
    position: absolute;
    top: 0;
    left: 0;
  }