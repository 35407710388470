.NavigationItems {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: -70px;
    display: flex;
    flex-flow: column;
    /* justify-content: center; */
    /* align-items: center; */
    height: 60px;
    background-color: white;
    font-family: 'Roboto Slab', serif;
    font-weight: bold;
    font-size: 15px;
    color: #707070;
}

.NavigationItems .TituloMenu{
    margin-top: 11px;
    padding: 0px 10px;
    /* margin: auto; */
    /* height: 49px; */
}

.NavigationItems a{
    text-decoration:none;
    color: #707070;
}

.NavigationItems a:hover,
.NavigationItems a:active,
.NavigationItems a.active {
    color: #1A2D00;
}

/* .NavigationItems .Seta{
    font-size: 12px;
} */

.NavigationItems .SubMenu1{
    display: none;
    
}

.Menu1:hover .SubMenu1 { 
    display: flex;
    flex-direction: column; 
    background-color: white;
}

.Menu1:hover .SubMenuTech{
    min-width: 220px;
    overflow-y: scroll;
    height: 500px;
}

.Menu1:hover .SubMenuTech::-webkit-scrollbar{
    width: 7px;
    height: 10px;
} 

.Menu1:hover .SubMenuTech::-webkit-scrollbar-track{
    box-shadow: #77B81E;
    border-radius: 10px;
}

.Menu1:hover .SubMenuTech::-webkit-scrollbar-thumb{
    background: #77B81E;
    border-radius: 10px;
}

.Menu1:hover .SubMenuTech::-webkit-scrollbar-thumb:hover{
    background: #2F4F2F
    ;
}

.NavigationItems li{
    list-style-type: none;
}

.NavigationItems .Options{
    margin-left: -30px;
}

.NavigationItems .DivMenuMobile{
    height: 2px;
    background-color: #DEDEDE;
}


@media (min-width: 800px){
    .NavigationItems {
        flex-flow: row;
    }

    .NavigationItems hr{
        display:none;
    }
}

@media (min-width: 1300px){
    .NavigationItems {
        flex-flow: row;
        height: 49px;
        justify-content: center;
        /* font-size: 15px; */
    }
}

@media (max-width: 800px){
    .NavigationItems .SubMenu1{
        /* display: block; */
    }

    .NavigationItems .SubMenu1:clicked {
        display: block;
    }
}
